import { combineReducers } from "redux";
import ui from "./ui";

import { setBearerTokenToHeader } from "services/web/helper";

import user from "./user";
import keyMetrics from "./DashboardOverview/keyMetrics";
import driverOverview from "./DashboardOverview/driverOverview";
import trends from "./DashboardOverview/trends";
import participation from "./DashboardOverview/participation";
import departmentOverview from "./DashboardOverview/departmentOverview";
import timeRange from "./dateRange";
import managerPerformance from "./DashboardOverview/managerPerformance";
import insights from "./DashboardOverview/insights";
import survey from "./survey";
import messageList from "./Messaging/MessageList";
import departmentListing from "./Department/listing";
import departmentKeyMetrics from "./Department/keyMetrics";
import departmentTrends from "./Department/trends";
import departmentInsights from "./Department/insights";
import departmentDriverOverview from "./Department/driverOverview";
import departmentParticipation from "./Department/participation";
import departmentManagerPerformance from "./Department/managerPerformance";
import departmentFilters from "./Department/filters";
import departmentMessageList from "./Department/messaging";
import singleMessageContent from "./Messaging/SingleMessaging";
import filters from "./Messaging/Filters";
import postMessage from "./Messaging/PostMessage";
import adminAccounts from "./adminAccounts";
import heatmapOverview from "./Heatmap/heatmapOverview";
import heatmapFilters from "./Heatmap/filters";
import surveyScheduler from "./SurveyScheduler";
import createSurvey from "./SurveyCreate";
import surveyTemplates from "./SurveyTemplates";
import manageQuestions from "./ManageQuestions";
import feedbackList from "./Feedback/feedbackList";
import pulseDetails from "./Feedback/pulse";
import performanceCheckin from "./Feedback/performanceCheckin";
import feedbackQuestions from "./Feedback/feedbackQuestions";
import superList from "./SuperList";
import oneOnOneList from "./OneOnOne/OneOnOneList";
import oneOnOneListDetails from "./OneOnOne/OneOnOneListDetails";
import oneOnOnePollingDetails from "./OneOnOne/OneOnOnePollingDetails";
import oneOnOneUsers from "./OneOnOne/OneOnOneUsers";
import reporteesDetail from "./ReporteesDetail";
import currentOneOnOne from "./OneOnOne/CurrentOneOnOne";
import talkingPoints from "./TalkingPoints";
import talkingPointTemplates from "./TalkingPointTemplates";
import otherPartytalkingPoints from "./OtherPartyTalkingPoints";
import followupItems from "./FollowupItems";
import oneOnOneNotes from "./Notes";
import suggestedItems from "./SuggestedItems";
import marketingLeads from "./MarketingLeads";
import oneOnOneReasons from "./OneOnOne/OneOnOneReasons";
import talkingPointsFromAnswers from "./TalkingPointsFromAnswers";
import calenderItems from "./CalenderItems";
import socketConnect from "./socketConnect";
import oneOnOneNotesSaveStatus from "./NotesSaveStatus";
import collectPerformanceRatingDetails from "./PerformanceRating/CollectPerformanceRating";
import showPerformanceRating from "./PerformanceRating/ShowPerformanceRating";
import collectProductivityDetails from "./Productivity/CollectProductivity";
import showProductivity from "./Productivity/ShowProductivity";
import appUpdated from "./appUpdated";
import actionItemsList from "./ActionItems";
import onboarding from "./Onboarding";
import employeeDataImport from "./ManageEmployees/EmployeeDataImport";
import employeeList from "./ManageEmployees/EmployeeList";
import departmentsList from "./ManageEmployees/DepartmentsList";
import driversList from "./ManageEmployees/DriversList";
import surveyEmployeeList from "./SurveyEmployeeList";
import managersList from "./ManageEmployees/ManagersList";
import goalTypeSearchValue from "./ManageEmployees/SearchDeptEmployee";
import billing from "./Billing";
import appSumo from "./AppSumo";
import surveyInfo from "./SurveyInfo";
import goalActivitiesList from "./Goals/GoalActivitiesList";
import goalsFilterList from "./Goals/GoalsFilterList";
import channelList from "./CoffeeConnect/ChannelList";
import stats from "reducers/CoffeeConnect/Stats";
import goalsObjectList from "reducers/Goals/GoalsObjectList";
import goalsIdsList from "reducers/Goals/GoalsIdsList";
import goalActivitiesDrawer from "reducers/Goals/GoalActivitiesDrawer";
import {
  goalsCycleList,
  goalsCycleIdsObjectList,
} from "reducers/Goals/GoalsCycleList";
import {
  goalsUserSpecificCycleList,
  goalsSpecificIdsObjectList,
} from "./Goals/GoalCycleUserSepcific";
import goalskeyResultIdsList from "reducers/Goals/GoalsKeyResultIdsList";
import goalsProgressTypeList from "reducers/Goals/GoalsProgressTypeList";
import goalAlignmentList from "reducers/Goals/GoalAlignmentList";
import analytics from "reducers/Analytics";
import deploymentInfo from "reducers/DeploymentInfo";
import notifications from "reducers/Notifications";

import pulseSurveyReducer from "./PulseSurvey";
import alignGoalReducer from "./Goals/AlignGoals/AlignGoals.reducer";
import surveyAnalysisReducer from "./SurveyAnalysis";
import goalMetaReducer from "containers/Goals/store/GoalMeta/GoalMeta.reducer";
import goalsListingFilterReducer from "./Goals/GoalsListingFilter/GoalsListingFilter.reducer";
import goalsMetaDataReducer from "./Goals/GoalsMetaData/GoalsMetaData.reducer";
import goalIntegrationsDetails from "./Goals/GoalsIntegrations";
import performanceReview from "./PerformanceReviews";
import bizReviewStore from "containers/Goals/pages/BizReviewPage/store/BizReview.reducer";
import goalsShowPageStore from "containers/Goals/pages/GoalsShowPage/store/GoalsShowPage.reducer";
import getMetaDataContent from "./Goals/DepartmentMetaData";
import projectsMetaDataStore from "../containers/Goals/pages/NewGoalsListingPage/store/ProjectsMetaData/ProjectMetaData.reducer";
import googleSheetProjectSyncStatus from "reducers/Goals/SyncProjects";
import CSVBoxConfig from "reducers/CSVBox";
import NotificationIntegrations from "reducers/NotificationIntegrations";
// new goals listing page
import goalsSaveAndCreateNext from "reducers/Goals/SaveAndCreateNext";
import goalsSummary from "./Goals/GoalsSummary/GoalsSummary";
import { milestoneCycles } from "reducers/Goals/MilestoneCycles";
import okrProgressBreakdown from "reducers/Goals/OkrProgressBreakdown";
import goalsSecondaryDrawer from "reducers/Goals/GoalsSecondaryDrawer";
import trackers from "./trackers";
import SurveyParticipation from "reducers/Engagement/Participation";
import goalsAttributeFilters from "./Goals/GoalAttributesFilters";
import ProductPermissions from "reducers/ProductPermissions";
import reviewListReducer from "../containers/Reviews/pages/ReviewsList/store/reducer";
import growthPath from "store/GrowthPath";

const appReducer = combineReducers({
  user,
  keyMetrics,
  driverOverview,
  trends,
  participation,
  departmentOverview,
  timeRange,
  managerPerformance,
  insights,
  survey,
  messageList,
  departmentListing,
  departmentKeyMetrics,
  departmentTrends,
  departmentInsights,
  departmentDriverOverview,
  departmentParticipation,
  departmentManagerPerformance,
  departmentFilters,
  departmentMessageList,
  filters,
  postMessage,
  singleMessageContent,
  ui,
  adminAccounts,
  heatmapOverview,
  heatmapFilters,
  surveyScheduler,
  createSurvey,
  surveyTemplates,
  manageQuestions,
  feedbackList,
  feedbackQuestions,
  superList,
  pulseDetails,
  performanceCheckin,
  oneOnOneList,
  reporteesDetail,
  currentOneOnOne,
  talkingPoints,
  otherPartytalkingPoints,
  followupItems,
  oneOnOneNotes,
  suggestedItems,
  marketingLeads,
  oneOnOneReasons,
  talkingPointTemplates,
  talkingPointsFromAnswers,
  calenderItems,
  socketConnect,
  oneOnOneListDetails,
  oneOnOneUsers,
  oneOnOneNotesSaveStatus,
  oneOnOnePollingDetails,
  collectPerformanceRatingDetails,
  showPerformanceRating,
  collectProductivityDetails,
  showProductivity,
  appUpdated,
  actionItemsList,
  onboarding,
  employeeDataImport,
  employeeList,
  goalTypeSearchValue,
  departmentsList,
  driversList,
  surveyEmployeeList,
  managersList,
  billing,
  appSumo,
  surveyInfo,
  goalActivitiesList,
  goalsFilterList,
  channelList,
  stats,
  goalsObjectList,
  goalActivitiesDrawer,
  goalsIdsList,
  goalsCycleList,
  goalsUserSpecificCycleList,
  goalsSpecificIdsObjectList,
  goalsCycleIdsObjectList,
  goalskeyResultIdsList,
  goalsProgressTypeList,
  goalAlignmentList,
  analytics,
  deploymentInfo,
  notifications,
  pulseSurveyStore: pulseSurveyReducer,
  alignGoalStore: alignGoalReducer,
  surveyAnalysisStore: surveyAnalysisReducer,
  goalMetaStore: goalMetaReducer,
  goalsListingFilterStore: goalsListingFilterReducer,
  goalsMetaDataStore: goalsMetaDataReducer,
  goalIntegrationsDetails: goalIntegrationsDetails,
  performanceReview,
  bizReviewStore,
  goalsShowPageStore,
  getMetaDataContent,
  projectsMetaDataStore,
  googleSheetProjectSyncStatus,
  goalsSaveAndCreateNext,
  CSVBoxConfig,
  NotificationIntegrations,
  milestoneCycles,
  okrProgressBreakdown,
  goalsSecondaryDrawer,
  trackers,
  SurveyParticipation,
  growthPath,
  goalsAttributeFilters,
  ProductPermissions,
  reviewListReducer,
  goalsSummary,
});

const rootReducer = (state, action) => {
  if (action.type === "LOGOUT_SUCCESS") {
    window.localStorage.removeItem("dashboard_auth_token");
    window.localStorage.removeItem("superAdmin");
    // Added to remove the persisted filters from goal show page
    window.sessionStorage.removeItem("activatedFilters");
    window.sessionStorage.removeItem("activeCycleAll");
    window.sessionStorage.removeItem("upcomingCycleAll");
    window.sessionStorage.removeItem("previousCycleAll");
    // removes bearer token from web service
    setBearerTokenToHeader();
    state = undefined;
  }
  return appReducer(state, action);
};

export default rootReducer;
