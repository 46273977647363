import React from "react";
import ReactDOM from "react-dom";
import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import logger from "redux-logger";
import { Provider } from "react-redux";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { unregister } from "./serviceWorker";
import rootReducer from "./reducers";
import Router from "./routes";
import "./index.scss";
import "antd/dist/antd.css";

import { initSentry } from "config/sentryConfig";
import { ChatwootAPI } from "Tracking";

import { APP_ENV } from "./config/api-config";

const superAdminToken = window.localStorage.getItem("super_admin_token");
const impersonatorAuthToken = window.localStorage.getItem(
  "impersonator_auth_token"
);

const initChatWoot = (init) => {
  if (superAdminToken || impersonatorAuthToken) {
    return;
  }
  init();
};

let store = null;
const queryClient = new QueryClient();
initSentry();

if (process.env.NODE_ENV === "development") {
  store = createStore(
    rootReducer,
    {},
    compose(
      applyMiddleware(thunk, logger),
      window.__REDUX_DEVTOOLS_EXTENSION__
        ? window.__REDUX_DEVTOOLS_EXTENSION__()
        : (f) => f
    )
  );

  const render = () => {
    ReactDOM.render(
      <Provider store={store}>
        <QueryClientProvider client={queryClient}>
          <Router />
        </QueryClientProvider>
      </Provider>,
      document.getElementById("root")
    );
  };

  render();

  if (module.hot) {
    // hot reload redux store
    module.hot.accept("./reducers", () => {
      store.replaceReducer(rootReducer);
    });
    // hot reload app
    module.hot.accept("./routes", () => {
      render();
    });
  }
} else {
  // Init Chatwoot in prod only, else it malfunctions and gets initialized in non prod environment
  if (APP_ENV === "PRODUCTION" || APP_ENV === "DEMO") {
    const { init } = ChatwootAPI();
    initChatWoot(init);
  }

  store = createStore(rootReducer, {}, applyMiddleware(thunk));

  ReactDOM.render(
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <Router />
      </QueryClientProvider>
    </Provider>,
    document.getElementById("root")
  );
}
export default store;
unregister();
