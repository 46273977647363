import {
  UPDATE_GOAL_MILESTONE_SAVE_STATUS,
  SET_SECONDARY_DRAWER_GOAL_DETAILS,
  UPDATE_KEY_RESULTS_MILESTONE_SAVE_STATUS,
} from "actions/constants";

const initialState = {
  goalTitle: null,
  progressType: null,
  progressStart: null,
  progressTarget: null,
  trackProgress: null,

  goalMilestones: {
    progressStart: null,
    progressTarget: null,
    isMilestoneDataSaved: true,
    keyResultsData: [],
  },
};

const goalsSecondaryDrawer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SECONDARY_DRAWER_GOAL_DETAILS: {
      const {
        goalTitle,
        progressType,
        progressStart,
        progressTarget,
        trackProgress,
      } = action.payload;
      const newState = {
        ...state,
        goalTitle,
        progressType,
        progressStart,
        progressTarget,
        trackProgress,
      };
      return newState;
    }

    case UPDATE_KEY_RESULTS_MILESTONE_SAVE_STATUS: {
      const {
        keyResultIndex,
        progressStart,
        progressTarget,
        saveStatus,
        updateOnlyStatus,
      } = action.payload;

      const updatedKeyResultsData = state.goalMilestones.keyResultsData;
      const existingKeyResultIndex = updatedKeyResultsData.findIndex(
        (kr) => kr.keyResultIndex === keyResultIndex
      );

      if (existingKeyResultIndex === -1) {
        updatedKeyResultsData.push({
          keyResultIndex,
          progressStart: progressStart,
          progressTarget: progressTarget,
          isMilestoneDataSaved: saveStatus,
        });
      } else {
        updatedKeyResultsData[existingKeyResultIndex] = {
          ...updatedKeyResultsData[existingKeyResultIndex],
          progressStart: updateOnlyStatus
            ? updatedKeyResultsData[existingKeyResultIndex].progressStart
            : progressStart,
          progressTarget: updateOnlyStatus
            ? updatedKeyResultsData[existingKeyResultIndex].progressTarget
            : progressTarget,
          isMilestoneDataSaved: saveStatus,
        };
      }

      const newState = {
        ...state,
        goalMilestones: {
          ...state.goalMilestones,
          keyResultsData: updatedKeyResultsData,
        },
      };

      return newState;
    }

    case UPDATE_GOAL_MILESTONE_SAVE_STATUS: {
      const { progressStart, progressTarget, saveStatus, updateOnlyStatus } =
        action.payload;

      const newState = {
        ...state,
        goalMilestones: {
          ...state.goalMilestones,
          progressStart: updateOnlyStatus
            ? state.goalMilestones.progressStart
            : progressStart,
          progressTarget: updateOnlyStatus
            ? state.goalMilestones.progressTarget
            : progressTarget,
          isMilestoneDataSaved: saveStatus,
        },
      };
      return newState;
    }

    default:
      return state;
  }
};

export default goalsSecondaryDrawer;
